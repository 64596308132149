import { Course } from '@/types/core/Course';
import { ApiClientOptions } from '@/types/apiclient';
import { buildApiClientFromNextJsContext } from '@/api/apiClient';
import { EvaluationCourseDTO } from '@/types/dto/EvaluationCourseDTO';
import { SearchResult } from '@/types/search/searchresult';
import { buildUrlFromSearch, Search } from '@/api/search';

export async function getCourse(
  idCourse: string,
  options?: ApiClientOptions
): Promise<Course> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/courses/${idCourse}` +
    `?fields[course]=id,title,duration,summary,skills,status,countSubscribedUsers,countCertifiedUsers,targets,successRate,description,keywords,media,designer,externalSource,externalSourceUrl` +
    `&includes=media,firstLevelChapters.childrens,chapters` +
    '&fields[file]=filename,originalFilename,size' +
    '&fields[chapter]=id,title,rank,type,parent';
  const response = await apiClient(url);
  return response.json();
}

export async function evaluateCourse(
  idCourse: string,
  evaluationCourse: EvaluationCourseDTO,
  options?: ApiClientOptions
): Promise<Response> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = `/api/courses/${idCourse}/evaluate`;
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify(evaluationCourse),
  });
}

export async function getCourses(
  options?: ApiClientOptions
): Promise<SearchResult<Course>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url =
    `/api/courses` +
    '?fields[course]=id,title,duration,designer,summary,skills,status,targets,successRate,description,externalSource,externalSourceUrl,keywords,media,updatedAt,countSubscribedUsers,countCertifiedUsers' +
    '&includes=media,chapters' +
    '&fields[chapter]=id,title,rank,type,parent' +
    '&fields[file]=filename,originalFilename,size,uploadId' +
    '&filters[status]=PUBLISHED' +
    '&order=createdAt';
  const response = await apiClient(url);
  return response.json();
}

export async function searchCourses(
  search: Search,
  options?: ApiClientOptions
): Promise<SearchResult<Course>> {
  const apiClient = await buildApiClientFromNextJsContext(options?.context);
  const url = buildUrlFromSearch(`/api/courses`, search);
  const response = await apiClient(url);
  return response.json();
}
