import { Badge } from '@chakra-ui/react';

export default function LearningBadge({
  bg,
  label,
}: {
  bg: string;
  label: string;
}) {
  return (
    <Badge
      display="flex"
      alignItems="center"
      gap="0.3rem"
      h="fit-content"
      bg={bg}
    >
      {label}
    </Badge>
  );
}
