import CardHeader from '@components/presentational/Cards/Parts/CardHeader';
import CardBody from '@components/presentational/Cards/Parts/CardBody';
import { Button, Flex, Text } from '@chakra-ui/react';
import FormationDetail from '@components/presentational/FormationDetail';
import CardFooter from '@components/presentational/Cards/Parts/CardFooter';
import ComplexCard from '@components/presentational/Cards/ComplexCard';
import React, { useMemo } from 'react';
import { Course } from '@/types/core/Course';
import { CourseSession } from '@/types/core/CourseSession';
import { CourseSession as CourseSessionConstants } from '@/types/constants';
import { useRouter } from 'next/router';
import LearningBadge from '../presentational/LearningBadge';

export default function CourseListItem({
  course,
  coursesSessions,
}: {
  course: Course;
  coursesSessions: CourseSession[] | null;
}) {
  const { push } = useRouter();
  const orderedChapters = [];

  const currentSession = useMemo<CourseSession | null>(() => {
    if (!coursesSessions || course.externalSource === true) return null;
    return (
      coursesSessions.find(session => session.course?.id === course.id) || null
    );
  }, [coursesSessions, course.id]);

  // Button label depends on the current session status
  const buttonLabel = useMemo(() => {
    if (!currentSession && course.externalSource) {
      return 'Découvrir la formation';
    }
    if (!currentSession && !course.externalSource) {
      return 'Débuter la formation';
    }
    if (currentSession.status === CourseSessionConstants.STATUS_IN_PROGRESS) {
      return 'Continuer la formation';
    }
    return 'Revoir la formation';
  }, [currentSession]);

  const onClickNavigate = () => {
    if (
      orderedChapters.length > 0 &&
      currentSession?.status === CourseSessionConstants.STATUS_IN_PROGRESS
    ) {
      let chapter = orderedChapters[0];
      if (currentSession.currentChapter) {
        chapter = currentSession.currentChapter;
      }
      push(
        {
          pathname: '/formations/chapters',
          query: {
            idCourseChapter: chapter.id,
          },
        },
        `/formations/${course.id}/chapters/${chapter.id}`
      );
    } else {
      push(
        {
          pathname: '/formations/formationDetail',
          query: {
            idCourse: course.id,
          },
        },
        `/formations/${course.id}`
      );
    }
  };

  return (
    <ComplexCard
      image={
        course.media?.filename
          ? `data/course/images/${course.media.filename}`
          : null
      }
      imageAlt={course.title}
      accentColor={course.externalSource ? 'cumulus.100' : 'menthe.100'}
      width={['calc(100% - 4rem)', '', '100%']}
      mx="auto"
    >
      <CardHeader
        title={course.title}
        badge={
          course.externalSource && (
            <LearningBadge bg="cumulus.100" label="Formation réservée IF/AF" />
          )
        }
      />
      <CardBody>
        <Flex direction="column" gap="1rem" fontSize="1rem">
          <Flex direction="column">
            <Text as="span" fontWeight="bold">
              Résumé
            </Text>
            <Text as="span">{course.summary}</Text>
          </Flex>
          <FormationDetail detail={course} />
        </Flex>
      </CardBody>
      <CardFooter>
        <Flex w="100%" justify={{ base: 'center', sm: 'flex-end' }}>
          <Button
            variant={course.externalSource ? 'cumulus' : 'mint'}
            size="sm"
            lineHeight="1.2em"
            onClick={() => onClickNavigate()}
          >
            {buttonLabel}
          </Button>
        </Flex>
      </CardFooter>
    </ComplexCard>
  );
}
