import {
  PiClockClockwiseThin,
  PiListChecksThin,
  PiTagThin,
  PiUserThin,
} from 'react-icons/pi';

import { Flex, Icon, Text, Badge, Box } from '@chakra-ui/react';

export default function FormationDetail({ detail }) {
  return (
    <>
      <Flex align="center" gap="0.5rem" mb="1em">
        <Icon
          as={PiClockClockwiseThin}
          boxSize={6}
          color="brand.100"
          display={{ base: 'none', md: 'inline-block' }}
        />
        <Text fontSize="1rem">
          <Text
            as="span"
            color={{ md: 'brand.100' }}
            fontWeight="600"
            display={{ md: 'inline-block' }}
            mr={{ md: '.5em' }}
          >
            Durée estimée :{' '}
          </Text>
          {detail.duration}
        </Text>
      </Flex>

      <Flex gap="0.5rem" mb="1em">
        <Icon
          as={PiListChecksThin}
          boxSize={6}
          color="brand.100"
          display={{ base: 'none', md: 'inline-block' }}
        />
        <Text as="div" fontSize="1rem">
          <Text
            as="span"
            color={{ md: 'brand.100' }}
            fontWeight="600"
            mb="0.5rem"
            display={{ md: 'block' }}
          >
            Compétences développées
            <Text as="span" display={{ base: 'inline', md: 'none' }}>
               :{' '}
            </Text>
          </Text>
          <Box
            display={{ md: 'flex' }}
            flexWrap={{ md: 'wrap' }}
            gap={{ md: '0.5rem' }}
          >
            {detail.skills.map((skill, index) => (
              <Text
                as="span"
                key={index}
                display={{ md: 'inline-block' }}
                mr={{ md: '1em' }}
              >
                {skill}
                <Text as="span" display={{ md: 'none' }}>
                  {index !== detail.skills.length - 1 && ', '}
                </Text>
              </Text>
            ))}
          </Box>
        </Text>
      </Flex>

      <Flex gap="0.5rem" mb="1em">
        <Icon
          as={PiTagThin}
          boxSize={6}
          color="brand.100"
          display={{ base: 'none', md: 'inline-block' }}
        />
        <Box fontSize="1rem">
          <Text color={{ md: 'brand.100' }} fontWeight="600" mb="0.5rem">
            Tags
            <Text as="span" display={{ base: 'inline', md: 'none' }}>
               :{' '}
            </Text>
          </Text>
          <Flex wrap="wrap" gap={['0.25rem', '', '0.5rem']}>
            {detail.keywords.map((tag, index) => (
              <Badge
                key={index}
                fontSize="1rem"
                textTransform="uppercase"
                whiteSpace="wrap"
              >
                {tag}
              </Badge>
            ))}
          </Flex>
        </Box>
      </Flex>
      {!detail.externalSource && (
        <Flex align="center" gap="0.5rem">
          <Icon
            as={PiUserThin}
            boxSize={6}
            color="brand.100"
            display={{ base: 'none', md: 'inline-block' }}
          />

          <Text fontSize="1rem">
            {detail.countSubscribedUsers}{' '}
            {detail.countSubscribedUsers > 1
              ? 'membres ont suivi cette formation'
              : 'membre a suivi cette formation'}
          </Text>
        </Flex>
      )}
    </>
  );
}
